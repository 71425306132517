import Head from 'next/head'
import DefaultErrorPage from 'next/error'
import { useRouter } from 'next/router'
import Layout from '../components/layouts/main'
import Loading from '../components/layouts/loading'
import API from '../contentful/api'
import { SitePage } from '../contentful/page.types'
import { Navlink } from '../contentful/navlinks.types'
import { GetStaticPaths, GetStaticProps, GetStaticPropsContext } from 'next'
import Markdown from 'react-markdown';
import util from "util";

type CmsPageProps = {
  page: SitePage;
  navbar: Navlink[];
  uri: string[] | string | undefined;
}

export default function CmsPage(props: CmsPageProps) {
  const { page, navbar, uri } = props;
  const router = useRouter();

  if (router.isFallback) {
    return (<Loading />)
  }

  if (!page) {
    return (
      <DefaultErrorPage statusCode={404} />
    );
  }

  return (
    <Layout title={page.title} navbar={navbar} uri={uri}>
      <Head>
        <title>{page.title}</title>
      </Head>
      <Markdown
        source={page.body}
        escapeHtml={false}
        renderers={{
          table: (p) => {
            return <table className="table table-bordered table-hover" style={{ marginTop: '2rem', width: '50%' }}>{p.children}</table>
          },
          link: (p) => {
            const url = new URL(p.href, 'https://www.decidedlyuncouth.com/');
            if (!url.hostname.includes('decidedlyuncouth.com')) {
              return <a href={p.href} rel='nofollow noreferrer noopener' target='_blank'>{p.children}</a>;
            }

            return <a href={p.href}>{p.children}</a>;

          }
        }}
      />
    </Layout>
  );
}

export const getStaticPaths: GetStaticPaths = async () => {
  const api = new API();
  const paths = await api.fetchAllPagePaths();

  return {
    paths,
    fallback: true
  };
}

export const getStaticProps: GetStaticProps = async (context: GetStaticPropsContext) => {
  const uri = context.params?.path ?? '';
  const path: string = uri instanceof Array ? uri.join('/') : uri;
  const api = new API();

  try {
    let page: SitePage = await api.fetchPageByURI(path);
    let navbar = await api.fetchNavbar();

    return {
      props: { page, navbar, uri },
      revalidate: 300 // 5 minutes
    };
  } catch {
    return { props: { page: false } };
  }
}

