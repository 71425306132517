import Head from 'next/head'
import Navbar from '../Navbar'
import Banner from '../Banner'
import Footer from '../Footer'
import { Navlink } from '../../contentful/navlinks.types'
import styles from './layout.module.css'
import utilStyles from '../styles/utils.module.css'
import Link from 'next/link'

export const siteTitle = 'Decidedly Uncouth'

export type LayoutProps = {
  children: React.ReactNode;
  home?: boolean;
  title: string;
  navbar: Navlink[];
  uri: string[] | string | undefined;
}

export default function Layout(props: LayoutProps) {
  const {
    children,
    home,
    title,
    navbar,
    uri
  } = props;

  if (process.browser) {
    // @ts-ignore
    window.whTooltips = { colorLinks: true, iconizeLinks: true, renameLinks: true };
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="decidedly uncouth, mal'ganis, wow, world of warcraft, raiding, pve, horde, 2 day" />
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-touch-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-touch-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-touch-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-touch-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-touch-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-touch-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon-180x180.png" />
        <link rel="icon" type="image/png" href="/favicon-32x32.png" sizes="32x32" />
        <link rel="icon" type="image/png" href="/android-chrome-192x192.png" sizes="192x192" />
        <link rel="icon" type="image/png" href="/favicon-96x96.png" sizes="96x96" />
        <link rel="icon" type="image/png" href="/favicon-16x16.png" sizes="16x16" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#000503" />
        <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
        <meta name="theme-color" content="#800000" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato:300,400,700" />
        <link rel="stylesheet" href="/assets/vendor/bootstrap/dist/css/bootstrap.min.css" />
        <link rel="stylesheet" href="/assets/vendor/flickity/dist/flickity.min.css" />
        <link rel="stylesheet" href="/assets/vendor/magnific-popup/dist/magnific-popup.css" />
        <link rel="stylesheet" href="/assets/vendor/slider-revolution/css/settings.css" />
        <link rel="stylesheet" href="/assets/vendor/slider-revolution/css/layers.css" />
        <link rel="stylesheet" href="/assets/vendor/slider-revolution/css/navigation.css" />
        <link rel="stylesheet" href="/assets/vendor/bootstrap-sweetalert/dist/sweetalert.css" />
        <link rel="stylesheet" href="/assets/vendor/social-likes/dist/social-likes_flat.css" />
        <script defer src="/assets/vendor/fontawesome-free/js/all.js"></script>
        <script defer src="/assets/vendor/fontawesome-free/js/v4-shims.js"></script>
        <link rel="stylesheet" href="/assets/css/youplay.css" />
        <link rel="stylesheet" href="/assets/css/custom.css" />
        <script src="/assets/vendor/jquery/dist/jquery.min.js"></script>
        <script src="https://wow.zamimg.com/widgets/power.js"></script>
      </Head>

      {/* Preloader  */}
      <div className="page-preloader preloader-wrapp">
        <img src="/assets/images/logo-du.png" alt="" />
        <div className="preloader"></div>
      </div>

      {/* Navbar  */}
      <Navbar data={navbar} uri={uri} />

      <div className="content-wrap">
        {/* Banner */}
        <Banner title={title} />

        {/* Main section */}
        <section className="youplay-content container">
          {children}
        </section>

        {/* Footer */}
        <Footer />
      </div>

      <script src="/assets/vendor/object-fit-images/dist/ofi.min.js"></script>
      <script src="/assets/vendor/HexagonProgress/jquery.hexagonprogress.min.js"></script>
      <script src="/assets/vendor/bootstrap/dist/js/bootstrap.min.js"></script>
      <script src="/assets/vendor/jarallax/dist/jarallax.min.js"></script>
      <script src="/assets/vendor/flickity/dist/flickity.pkgd.min.js"></script>
      <script src="/assets/vendor/jquery-countdown/dist/jquery.countdown.min.js"></script>
      <script src="/assets/vendor/magnific-popup/dist/jquery.magnific-popup.min.js"></script>
      <script src="/assets/vendor/slider-revolution/js/jquery.themepunch.tools.min.js"></script>
      <script src="/assets/vendor/slider-revolution/js/jquery.themepunch.revolution.min.js"></script>
      <script src="/assets/vendor/imagesloaded/imagesloaded.pkgd.min.js"></script>
      <script src="/assets/vendor/isotope-layout/dist/isotope.pkgd.min.js"></script>
      <script src="/assets/vendor/bootstrap-validator/dist/validator.min.js"></script>
      <script src="/assets/vendor/bootstrap-sweetalert/dist/sweetalert.min.js"></script>
      <script src="/assets/vendor/social-likes/dist/social-likes.min.js"></script>
      <script src="/assets/js/youplay.min.js"></script>
      <script src="/assets/js/youplay-init.js"></script>
    </>
  )
}