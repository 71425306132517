import React from "react";
// import styled from "styled-components";
import ReactMarkdown from "react-markdown";

// const Image = styled.img`
//   width: 80%;
//   height: 300px;
//   object-fit: cover;
// `;

const defaultProps = {
  size: 'xsmall'
}

export type BannerProps = {
  title: string;
  size?: "xsmall" | "small" | "big" | "full"
};

export const Banner = (props: BannerProps) => {
  const { title, size } = props;
  return (
    <section className={`youplay-banner banner-top youplay-banner-parallax ${size}`}>
      <div className="image" data-speed="0.4">
        <img src="/assets/images/sl-lk.jpg" alt="" className="jarallax-img" />
      </div>
      <div className="info">
        <div>
          <div className="container">
            <h1 className="h1">{title}</h1>
          </div>
        </div>
      </div>
    </section >
  );
};

Banner.defaultProps = defaultProps;

export default Banner;
