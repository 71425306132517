import Head from 'next/head'
import Navbar from '../Navbar'
import Banner from '../Banner'
import Footer from '../Footer'
import styles from './layout.module.css'
import utilStyles from '../styles/utils.module.css'
import Link from 'next/link'

export const siteTitle = 'Decidedly Uncouth'

export default function Layout() {
  return (
    <div>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="decidedly uncouth, mal'ganis, wow, world of warcraft, raiding, pve, horde" />
        <link rel="icon" type="image/png" href="/assets/images/dark/icon.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato:300,400,700" />
        <link rel="stylesheet" href="/assets/vendor/bootstrap/dist/css/bootstrap.min.css" />
        <link rel="stylesheet" href="/assets/vendor/flickity/dist/flickity.min.css" />
        <link rel="stylesheet" href="/assets/vendor/magnific-popup/dist/magnific-popup.css" />
        <link rel="stylesheet" href="/assets/vendor/slider-revolution/css/settings.css" />
        <link rel="stylesheet" href="/assets/vendor/slider-revolution/css/layers.css" />
        <link rel="stylesheet" href="/assets/vendor/slider-revolution/css/navigation.css" />
        <link rel="stylesheet" href="/assets/vendor/bootstrap-sweetalert/dist/sweetalert.css" />
        <link rel="stylesheet" href="/assets/vendor/social-likes/dist/social-likes_flat.css" />
        <script defer src="/assets/vendor/fontawesome-free/js/all.js"></script>
        <script defer src="/assets/vendor/fontawesome-free/js/v4-shims.js"></script>
        <link rel="stylesheet" href="/assets/css/youplay.css" />
        <link rel="stylesheet" href="/assets/css/custom.css" />
        <script src="/assets/vendor/jquery/dist/jquery.min.js"></script>
      </Head>

      {/* Preloader  */}
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        background: '#080325',
        zIndex: 100000000
      }}>
        <img src="/assets/images/logo-du.png" alt="" style={{
          position: 'absolute',
          bottom: '50%',
          left: '50%',
          width: '180px',
          marginBottom: '-10px',
          marginLeft: '-90px',
          zIndex: 2,
          WebkitAnimation: 'youplay-preloader-fade .5s',
          animation: 'youplay-preloader-fade .5s'
        }} />
        <div className="preloader" style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-100px',
          marginLeft: '-100px'
        }}></div>
      </div>

      <script src="/assets/vendor/object-fit-images/dist/ofi.min.js"></script>
      <script src="/assets/vendor/HexagonProgress/jquery.hexagonprogress.min.js"></script>
      <script src="/assets/vendor/bootstrap/dist/js/bootstrap.min.js"></script>
      <script src="/assets/vendor/jarallax/dist/jarallax.min.js"></script>
      <script src="/assets/vendor/flickity/dist/flickity.pkgd.min.js"></script>
      <script src="/assets/vendor/jquery-countdown/dist/jquery.countdown.min.js"></script>
      <script src="/assets/vendor/magnific-popup/dist/jquery.magnific-popup.min.js"></script>
      <script src="/assets/vendor/slider-revolution/js/jquery.themepunch.tools.min.js"></script>
      <script src="/assets/vendor/slider-revolution/js/jquery.themepunch.revolution.min.js"></script>
      <script src="/assets/vendor/imagesloaded/imagesloaded.pkgd.min.js"></script>
      <script src="/assets/vendor/isotope-layout/dist/isotope.pkgd.min.js"></script>
      <script src="/assets/vendor/bootstrap-validator/dist/validator.min.js"></script>
      <script src="/assets/vendor/bootstrap-sweetalert/dist/sweetalert.min.js"></script>
      <script src="/assets/vendor/social-likes/dist/social-likes.min.js"></script>
      <script src="/assets/js/youplay.min.js"></script>
      <script src="/assets/js/youplay-init.js"></script>
    </div >
  )
}